import { Component, Input } from '@angular/core';

@Component({
  selector: 'casa-doc-skeleton-text',
  templateUrl: './skeleton-text.component.html',
  styleUrl: './skeleton-text.component.scss',
  standalone: false,
})
export class SkeletonTextComponent {
  @Input() lines = 3;
}
