import { Component, Input } from '@angular/core';
import { User } from '@common/core/models';

@Component({
    selector: 'casa-doc-user-info-card',
    templateUrl: './user-info-card.component.html',
    styleUrl: './user-info-card.component.scss',
    standalone: false
})
export class UserInfoCardComponent {
  @Input() user: User;
  @Input() title: string;

  sendEmail(email: string): void {
    if (email) {
      window.location.href = `mailto:${email}`;
    }
  }

  callPhone(phoneNumber: number): void {
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    }
  }
}
