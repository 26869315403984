import { Component, OnInit } from '@angular/core';
import { EBitfCloseEventStatus } from '@common/enums';
import { EUsersRole } from '@common/enums/users-role.enum';
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';
import { APP_VERSION } from '@env/version';
import { AuthService, StoreService } from '@web/core/services';

@Component({
    selector: 'casa-doc-menu',
    templateUrl: './menu.component.html',
    styleUrl: './menu.component.scss',
    standalone: false
})
export class MenuComponent implements OnInit {
  appVersion = APP_VERSION;
  eUsersRole = EUsersRole;
  infoMail = 'info@casa-doc.it';

  constructor(
    private bitfMatSidenavService: BitfMatSidenavService,
    private authService: AuthService,
    public storeService: StoreService
  ) {}

  ngOnInit(): void {}

  closeSidenav() {
    this.bitfMatSidenavService.close({ status: EBitfCloseEventStatus.CLOSE });
  }

  onSignOut() {
    this.authService.signOut();
  }

  openCookiePreferences() {
    const btn = document.getElementsByClassName('lb-cs-settings-link') as HTMLCollectionOf<HTMLElement>;
    btn[0]?.click();
  }
}
