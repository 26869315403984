import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';

import { CONSTANTS } from '@constants';
import { StorageService } from '@services';
import { IBitfLanguage } from './bitf-dynamic-locale.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BitfDynamicLocaleService {
  private _locale: IBitfLanguage;
  public localeChange$ = new BehaviorSubject<string>(undefined);

  constructor(private translateService: TranslateService, private storageService: StorageService) {}

  async init() {
    return this.setLocaleNoSaveToStorage(this.defaultLocale);
  }

  get locale() {
    return this._locale;
  }

  get defaultLocale(): IBitfLanguage {
    const fromStorage = this.storageService.data.selectedLanguage;
    const navigatorLanguage = navigator.language;
    const navigatorLanguageShort = navigator.language.split('-')[0];
    const fromNavigator =
      CONSTANTS.SUPPORTED_LANGUAGES.find((lang) => lang.code === navigatorLanguage) ||
      CONSTANTS.SUPPORTED_LANGUAGES.find((lang) => lang.code.toLowerCase() === navigatorLanguageShort);
    const result = fromStorage || (fromNavigator ? fromNavigator : CONSTANTS.DEFAULT_LANGUAGE);

    return result;
  }

  async setLocale(locale: IBitfLanguage): Promise<void> {
    await this.setLocaleNoSaveToStorage(locale);
    this.storageService.data = { selectedLanguage: this._locale };
  }

  async setLocaleNoSaveToStorage(locale: IBitfLanguage): Promise<void> {
    this.localeChange$.next(locale.code);
    const localeCode = locale.code;
    const translateP = this.translateService.use(localeCode).toPromise();
    await this.registerLocale(localeCode);
    await translateP;

    // NOTE: _locale must be set at the end, because it is read from pipes and locale
    // data must be already loaded
    this._locale = locale;
  }

  async registerLocale(localeCode: string): Promise<unknown> {
    const localeData = await this.loadLocale(localeCode);
    return registerLocaleData(localeData);
  }

  private async loadLocale(locale: string): Promise<unknown> {
    const module = await import('../../../../../../locale/' + locale + '.ts');
    return module.default;
  }
}
