import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@common/shared/common-shared.module';
import { CDK_MODULES, MATERIAL_MODULES, MATERIAL_PROVIDERS } from './material-modules';

import { ScrollingModule } from '@angular/cdk/scrolling';

import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { BitfFormItemContainerComponent } from '@bitf/core/components/ui/form-item/bitf-form-item-container/bitf-form-item-container.component';
import { BitfMatLanguageSelectorComponent } from '@bitf/core/components/ui/language-selector/material/bitf-mat-language-selector.component';
import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';
import { BitfMatLoaderComponent } from '@bitf/core/components/ui/loader/material/bitf-mat-loader/bitf-mat-loader.component';
import { BitfMatPaginatorComponent } from '@bitf/core/components/ui/paginator/material/bitf-mat-paginator.component';
import { BitfMatSearchComponent } from '@bitf/core/components/ui/search/material/bitf-mat-search.component';
import { DateAdapterService } from '@common/core/services';
import { BitfDndFileComponent } from '@common/libs/bitforce/core/components/ui/dnd-file';
import { BitfMatFormItemComponent } from '@common/libs/bitforce/core/components/ui/form-item/material/mat-form-item/bitf-mat-form-item.component';
import { BitfImageComponent } from '@common/libs/bitforce/core/components/ui/image/bitf-image/bitf-image.component';
import { BitfMatInfiniteScrollTriggerComponent } from '@common/libs/bitforce/core/components/ui/infinite-scroll/material/bitf-mat-infinite-scroll-trigger/bitf-mat-infinite-scroll-trigger.component';
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { BitfTouchEventDirective } from '@common/libs/bitforce/directives/bitf-touch-events/bitf-touch-event.directive';
import { BitfUiRoleManagerDirective } from '@common/libs/bitforce/directives/ui-role-manager/bitf-ui-role-manager.directive';
import { BitfBytesPipe } from '@common/libs/bitforce/pipes/bitf-bytes.pipe';
import { HeaderComponent } from '@web/core/components/header/header.component';
import { MenuComponent } from '@web/core/components/menu/menu.component';
import { PageHeaderComponent } from '@web/shared/components/page-header/page-header.component';
import { BookletsDocumentsComponent } from './components/booklets-documents/booklets-documents.component';
import { DocumentManagerComponent } from './components/document-manager/document-manager.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { ImagePreviewDialogComponent } from './components/image-preview-dialog/image-preview-dialog.component';
import { SkeletonTextComponent } from './components/skeleton-text/skeleton-text.component';
import { UserInfoCardComponent } from './components/user-info-card/user-info-card.component';

const SHARED_MODULES = [CommonSharedModule, RouterModule, MATERIAL_MODULES, CDK_MODULES, ScrollingModule];
const SHARED_COMPONENTS = [
  BitfLoaderComponent,
  BitfMatPaginatorComponent,
  BitfUiRoleManagerDirective,
  BitfTouchEventDirective,
  BitfMatOkCancelDialogComponent,
  BitfMatLoaderComponent,
  BitfImageComponent,
  BitfMatInfiniteScrollTriggerComponent,
  BitfMatSearchComponent,
  BitfFormItemContainerComponent,
  BitfMatFormItemComponent,
  BitfDndFileComponent,
  HeaderComponent,
  MenuComponent,
  PageHeaderComponent,
  UserInfoCardComponent,
  BitfFormItemContainerComponent,
  DocumentManagerComponent,
  FileUploaderComponent,
  BitfDndFileComponent,
  BitfMatLanguageSelectorComponent,
  BookletsDocumentsComponent,
  ImagePreviewDialogComponent,
  SkeletonTextComponent,
];

const SHARED_DIRECTIVES = [];
const SHARED_PIPES = [BitfBytesPipe];
const PROVIDERS = [DatePipe, { provide: DateAdapter, useClass: DateAdapterService }];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, SHARED_PIPES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, SHARED_PIPES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...PROVIDERS, ...MATERIAL_PROVIDERS],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
