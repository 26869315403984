<mat-card class="p-3 mb-3"
  *ngIf="user">

  <div class="d-flex">

    <img class="avatar-image"
      *ngIf="user.photo"
      [src]="user.photo.url"
      alt="Avatar">

    <div class="avatar-container"
      *ngIf="!user.photo">
      <mat-icon class="avatar-icon">person</mat-icon>
    </div>

    <div class="ml-2 w-100 d-flex align-items-center">
      <div>
        <h3 class="m-0 text-break">{{ user.name }} {{ user.surname }}</h3>

        <div class="text-muted text-break">{{ title}} </div>
      </div>
    </div>
  </div>
  <div>
    <div class="d-flex justify-content-between mt-3 flex-nowrap"
      *ngIf="user.phoneNumber">
      <div class="d-flex align-items-center flex-1">
        <mat-icon class="info-icon pr-2 mr-2">phone</mat-icon>
        <a class="mat-body-small">{{ user.phoneNumber ? user.phoneNumber : 'N/D' }}</a>
      </div>

      <a class="mat-body-small text-muted ml-2 text-right text-nowrap"
        [href]="'tel:' + user.phoneNumber">
        <u>{{ 'COMMON.LABEL.CALL' | translate }}</u>
      </a>
    </div>

    <div class="d-flex justify-content-between mt-3 flex-nowrap"
      *ngIf="user.email">
      <div class="d-flex flex-1">
        <mat-icon class="info-icon pr-2 mr-2">email</mat-icon>
        <a class="mat-body-small w-100 text-break">{{ user.email ? user.email : 'N/D' }}</a>
      </div>


      <a class="mat-body-small text-muted ml-2 text-right text-nowrap"
        [href]="'mailto:' + user.email">
        <u>{{ 'COMMON.LABEL.SEND_EMAIL' | translate }}</u>
      </a>
    </div>
  </div>
</mat-card>