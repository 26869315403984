import { Component, OnInit } from '@angular/core';

import { MenuComponent } from '../menu/menu.component';
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';
import { EBitfSidenavMode, EBitfSidenavPosition } from '@common/enums';
import { StoreService } from '@common/services';

@Component({
    selector: 'casa-doc-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    standalone: false
})
export class HeaderComponent implements OnInit {
  constructor(private bitfMatSidenavService: BitfMatSidenavService, public storeService: StoreService) {}

  ngOnInit(): void {}

  onOpenSidenav() {
    this.bitfMatSidenavService
      .open({
        component: MenuComponent,
        sidenavOptions: {
          mode: EBitfSidenavMode.OVER,
          position: EBitfSidenavPosition.END,
          disableClose: true,
        },
      })
      .subscribe();
  }
}
