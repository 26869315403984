import { Component } from '@angular/core';

import { AppSessionService } from '@services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  constructor(private appSessionService: AppSessionService) {
    this.appSessionService.init();
  }
}
