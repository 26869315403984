import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EBitfCloseEventStatus } from '@common/enums';
import { IBitfCloseEvent } from '@common/interfaces';

@Component({
    selector: 'casa-doc-image-preview-dialog',
    templateUrl: './image-preview-dialog.component.html',
    styleUrl: './image-preview-dialog.component.scss',
    standalone: false
})
export class ImagePreviewDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ImagePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { imageUrl: string }
  ) {}

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<any>);
  }
}
