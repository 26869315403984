import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { bitfIsCallingApi } from '@bitf/utils/bitf-urls.utils';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';
import { EBitfInterceptors } from '@enums';

import { CONSTANTS } from '@constants';
import { environment } from '@env/environment';
import { StorageService } from '@services';

@Injectable()
export class BitfLanguageInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(EBitfInterceptors.BITF_LANGUAGE_INTERCEPTOR)) {
      const newReq = req.clone({
        headers: req.headers.delete(EBitfInterceptors.BITF_LANGUAGE_INTERCEPTOR),
      });
      return next.handle(newReq);
    }
    if (bitfIsCallingApi(environment, req) && !req.url.includes(environment.loggerConfig.logUrl)) {
      const language =
        bitfGetProp(this.storageService.data, 'selectedLanguage') || CONSTANTS.DEFAULT_LANGUAGE;
      const newReq = req.clone({ headers: req.headers.set('Language', language.code) });
      return next.handle(newReq);
    }
    return next.handle(req);
  }
}
