import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BitfJwtAuthService } from '@bitf/services/auth/jwt/bitf-jwt-auth.service';

import { EBitfAuthState } from '@enums';
import { IBitfApiRequest, IBitfApiResponse } from '@interfaces';
import { BitfStrapiJwtService } from './bitf-strapi-rest.service';
import { User } from '@models';
import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';

@Injectable({
  providedIn: 'root',
})
export abstract class BitfStrapiRestAuthService extends BitfJwtAuthService {
  bitfStrapiService: BitfStrapiJwtService;
  tokenAlertTresholdMinutes = 5;

  constructor(protected injector: Injector) {
    super(injector);
    this.bitfStrapiService = this.injector.get(BitfStrapiJwtService);
  }

  listenForAuthStateChange(): Observable<EBitfAuthState> {
    return this.authState$.pipe(
      tap((authState: EBitfAuthState) => {
        if (authState === EBitfAuthState.TOKEN_RETRIEVED) {
          this.usersService.getMe().subscribe(
            (response: IBitfApiResponse<User>) => {
              this.setUser(response.content);
              this.onLoginSuccess();
              this.handleRedirect();
            },
            () => {
              this.onLoginError();
            }
          );
        }
      })
    );
  }

  handleAuthentication(requestParams: IBitfApiRequest) {
    this.authState$.next(EBitfAuthState.LOGIN_IN_PROGRESS);
    this.bitfStrapiService.getToken(requestParams).subscribe(
      response => {
        this.signIn({ encodedToken: response.content.jwt });
      },
      response => {
        let message = '';
        switch (response?.error?.content?.error?.message) {
          case 'Invalid identifier or password':
            message = this.translateService.instant(bitfToTranslate('BITF.AUTH.EMAIL_OR_PASSWORD_INVALID'));
            break;
          default:
            break;
        }
        this.onLoginError(message);
      }
    );
  }
}
