import { Component } from '@angular/core';

import { BitfLanguageSelectorComponent } from '../bitf-language-selector.component';

@Component({
  selector: 'bitf-mat-language-selector',
  templateUrl: './bitf-mat-language-selector.component.html',
  styleUrls: ['./bitf-mat-language-selector.component.scss'],
  standalone: false,
})
export class BitfMatLanguageSelectorComponent extends BitfLanguageSelectorComponent {}
