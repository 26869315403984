import { BitfStrapiModel } from '@common/libs/bitforce/core/models';
import { User } from './user.model';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { EBookletAttachmentName } from '@common/enums/attachments.enum';
import { BookletAttachments } from './booklet-attachments.model';
import get from 'get-value';

export class Booklet extends BitfStrapiModel {
  id?: number;
  documentId?: string;
  sellerName: string;
  sellerSurname: string;
  sellerEmail?: string;
  sellerMobilePhone?: number;
  propertyAddress: string;
  sellerFiscalCode?: string;
  propertyDescription?: string;
  propertyLastWorkData?: Date; // TODO: fix the name Date not Data
  technicalReportSurveyor?: User;
  agent?: User;
  inviteSellerEmail?: string;
  bookletAttachments: BookletAttachments;
  secretaries?: User[];
  apeSurveyor?: User;
  extraSurveyor?: User;
  propertyDeedOfSale?: Date;
  bookletStartDate?: Date;
  bookletEndDate?: Date;
  apeStartDate?: Date;
  apeEndDate?: Date;
  technicalReportStartDate?: Date;
  technicalReportEndDate?: Date;
  extraStartDate?: Date;
  extraEndDate?: Date;
  technicalReportSurveyorAccessToRecordsDate?: Date;
  accounts?: User[];
  extraNote?: string;
  seller?: User;
  technicalReportSurveyorNote?: string;
  apeSurveyorNote?: string;
  extraSurveyorNote?: string;
  sellerAddress?: string;
  requestForDeedOfOrigin?: boolean;
  bookletNotes?: string;
  technicalReportPrice?: number;
  requestForDeedOfOriginPrice?: number;
  extraPrice?: number;
  accessToRecordsPrice?: number;
  requestForApe?: boolean;
  apePrice?: number;
  publishedAt?: string;
  createdBy?: User;
  updatedBy?: User;
  locale?: string;
  localizations?: any[];
  extraContractHtml?: string;
  enableSharing?: boolean;

  constructor(data: Partial<Booklet> = {}) {
    super({ data });
    if (data.bookletStartDate) {
      this.bookletStartDate = new Date(data.bookletStartDate);
    }
    if (data.bookletEndDate) {
      this.bookletEndDate = new Date(data.bookletEndDate);
    }
    if (data.apeStartDate) {
      this.apeStartDate = new Date(data.apeStartDate);
    }
    if (data.apeEndDate) {
      this.apeEndDate = new Date(data.apeEndDate);
    }
    if (data.technicalReportStartDate) {
      this.technicalReportStartDate = new Date(data.technicalReportStartDate);
    }
    if (data.technicalReportEndDate) {
      this.technicalReportEndDate = new Date(data.technicalReportEndDate);
    }
    if (data.extraStartDate) {
      this.extraStartDate = new Date(data.extraStartDate);
    }
    if (data.extraEndDate) {
      this.extraEndDate = new Date(data.extraEndDate);
    }
    if (data.propertyLastWorkData) {
      this.propertyLastWorkData = new Date(data.propertyLastWorkData);
    }
    if (data.technicalReportSurveyorAccessToRecordsDate) {
      this.technicalReportSurveyorAccessToRecordsDate = new Date(
        data.technicalReportSurveyorAccessToRecordsDate
      );
    }
    if (data.propertyDeedOfSale) {
      this.propertyDeedOfSale = new Date(data.propertyDeedOfSale);
    }

    if (data.seller) {
      this.seller = new User(this.seller);
    }
    if (data.agent) {
      this.agent = new User(this.agent);
    }
    if (data.accounts) {
      this.accounts = data.accounts.map((accountData) => new User(accountData));
    }
    if (data.secretaries) {
      this.secretaries = data.secretaries.map((secretaryData) => new User(secretaryData));
    }
    if (data.technicalReportSurveyor) {
      this.technicalReportSurveyor = new User(this.technicalReportSurveyor);
    }
    if (data.apeSurveyor) {
      this.apeSurveyor = new User(this.apeSurveyor);
    }
    if (data.extraSurveyor) {
      this.extraSurveyor = new User(this.extraSurveyor);
    }

    this.bookletAttachments = new BookletAttachments(data.bookletAttachments);
  }

  get serialised() {
    return {
      id: this.id,
      documentId: this.documentId,
      sellerName: this.sellerName,
      sellerSurname: this.sellerSurname,
      sellerEmail: this.sellerEmail,
      sellerMobilePhone: this.sellerMobilePhone,
      propertyAddress: this.propertyAddress,
      sellerFiscalCode: this.sellerFiscalCode,
      propertyDescription: this.propertyDescription,
      propertyLastWorkData: this.propertyLastWorkData,
      technicalReportSurveyor: this.technicalReportSurveyor,
      agent: this.agent,
      inviteSellerEmail: this.inviteSellerEmail,
      bookletAttachments: this.bookletAttachments?.serialised,
      secretaries: this.secretaries,
      apeSurveyor: this.apeSurveyor,
      extraSurveyor: this.extraSurveyor,
      propertyDeedOfSale: this.propertyDeedOfSale,
      bookletStartDate: this.bookletStartDate,
      bookletEndDate: this.bookletEndDate,
      apeStartDate: this.apeStartDate,
      apeEndDate: this.apeEndDate,
      technicalReportStartDate: this.technicalReportStartDate,
      technicalReportEndDate: this.technicalReportEndDate,
      extraStartDate: this.extraStartDate,
      extraEndDate: this.extraEndDate,
      technicalReportSurveyorAccessToRecordsDate: this.technicalReportSurveyorAccessToRecordsDate,
      accounts: this.accounts,
      extraNote: this.extraNote,
      seller: this.seller,
      technicalReportSurveyorNote: this.technicalReportSurveyorNote,
      apeSurveyorNote: this.apeSurveyorNote,
      extraSurveyorNote: this.extraSurveyorNote,
      sellerAddress: this.sellerAddress,
      requestForDeedOfOrigin: this.requestForDeedOfOrigin,
      bookletNotes: this.bookletNotes,
      technicalReportPrice: this.technicalReportPrice,
      requestForDeedOfOriginPrice: this.requestForDeedOfOriginPrice,
      extraPrice: this.extraPrice,
      accessToRecordsPrice: this.accessToRecordsPrice,
      requestForApe: this.requestForApe,
      apePrice: this.apePrice,
      publishedAt: this.publishedAt,
      createdBy: this.createdBy,
      updatedBy: this.updatedBy,
      locale: this.locale,
      localizations: this.localizations,
      extraContractHtml: this.extraContractHtml,
    };
  }

  get state() {
    const state = {
      dashboard: {
        warnings: [],
        showInformationWarningIcon: false,
        showSurveyorsWarningIcon: false,
        showContractsWarningIcon: false,
        showDocumentsWarningIcon: false,
      },
      information: {
        warnings: [],
      },
      surveyors: {
        warnings: [],
      },
      contracts: {
        warnings: [],
        canSignCasaDocContract: false,
        canSignExtraContract: false,
      },
      documents: {
        warnings: [],
      },
      apeAndDeedOfOrigin: {
        warnings: [],
      },
    };

    const hasInformationWarnings = this.hasInformationWarnings();

    if (hasInformationWarnings) {
      state.dashboard.showInformationWarningIcon = true;
      state.information.warnings = [bitfToTranslate('COMMON.WARNINGS.MISSING_REQUIRED_DATA')];
    } else {
      if (
        this.bookletAttachments?.sellerIdentityCard?.file &&
        !this.bookletAttachments?.sellerIdentityCard?.compliant
      ) {
        state.dashboard.showInformationWarningIcon = true;
        state.information.warnings = [bitfToTranslate('COMMON.WARNINGS.IDENTITY_CARD_UNDER_CHECK')];
      }
    }

    //contracts

    if (hasInformationWarnings) {
      //controllo che ci siano tutti i dati necessari per i contratti
      state.contracts.warnings = [bitfToTranslate('COMMON.WARNINGS.MISSING_REQUIRED_DATA_FOR_CONTRACTS')];
      state.dashboard.showContractsWarningIcon = true;
    } else if (
      //controllo che il documento sia stato caricato e verificato
      this.bookletAttachments?.sellerIdentityCard?.file &&
      !this.bookletAttachments?.sellerIdentityCard?.compliant
    ) {
      state.contracts.warnings = [bitfToTranslate('COMMON.WARNINGS.IDENTITY_CARD_UNDER_CHECK')];
      state.dashboard.showContractsWarningIcon = true;
    } else {
      if (!this.technicalReportPrice) {
        //controllo che il prezzo della relazione tecnica sia stato inserito
        state.contracts.warnings = [bitfToTranslate('COMMON.WARNINGS.WAITING_FOR_TECHNICAL_REPORT_PRICE')];
        state.dashboard.showContractsWarningIcon = true;
      } else {
        // controllo che il contratto sia stato firmato
        if (!this.bookletAttachments?.[EBookletAttachmentName.CASA_DOC_CONTRACT]?.file) {
          state.contracts.warnings = [bitfToTranslate('COMMON.WARNINGS.MUST_SIGN_CASADOC_CONTRACTS')];
          state.contracts.canSignCasaDocContract = true;
          state.dashboard.showContractsWarningIcon = true;
        } else {
          //controllo che il contratto sia compliant
          if (!this.bookletAttachments?.[EBookletAttachmentName.CASA_DOC_CONTRACT]?.signed) {
            state.contracts.warnings = [bitfToTranslate('COMMON.WARNINGS.CASA_DOC_CONTRACT_ON_VERIFICATION')];
            state.dashboard.showContractsWarningIcon = true;
          }
        }
      }
    }

    if (
      this.extraContractHtml &&
      this.bookletAttachments?.[EBookletAttachmentName.CASA_DOC_CONTRACT]?.signed &&
      !this.bookletAttachments?.[EBookletAttachmentName.EXTRA_CONTRACT]?.signed
    ) {
      if (!this.bookletAttachments?.[EBookletAttachmentName.EXTRA_CONTRACT]?.file) {
        state.contracts.warnings = [bitfToTranslate('COMMON.WARNINGS.MUST_SIGN_EXTRA_CONTRACTS')];
        state.contracts.canSignExtraContract = true;
      } else if (!this.bookletAttachments?.[EBookletAttachmentName.EXTRA_CONTRACT]?.signed) {
        state.contracts.warnings = [bitfToTranslate('COMMON.WARNINGS.EXTRA_CONTRACT_ON_VERIFICATION')];
      }
      state.dashboard.showContractsWarningIcon = true;
    }

    //documents not Compliant
    if (this.hasDocumentsNotCompliant()) {
      state.dashboard.showDocumentsWarningIcon = true;
      state.documents.warnings = [
        bitfToTranslate('COMMON.WARNINGS.DOCUMENTS_NOT_COMPLIANT_OR_UNDER_VERIFICATION'),
      ];
    }
    //Documents Unpaid
    if (this.hasDocumentsUnpaid()) {
      state.dashboard.showDocumentsWarningIcon = true;
      state.documents.warnings = [bitfToTranslate('COMMON.WARNINGS.DOCUMENTS_NOT_PAID')];
    }
    //Ape and Deed of Origin
    if (this.hasApeNotLoaded()) {
      state.dashboard.showDocumentsWarningIcon = true;
      state.apeAndDeedOfOrigin.warnings.push(bitfToTranslate('COMMON.WARNINGS.APE_NOT_LOADED'));
    }
    if (this.hasDeedOfOriginNotLoaded()) {
      state.dashboard.showDocumentsWarningIcon = true;
      state.apeAndDeedOfOrigin.warnings.push(bitfToTranslate('COMMON.WARNINGS.DEED_OF_ORIGIN_NOT_LOADED'));
    }

    if (this.hasApeSurveyorPicturesNotLoaded()) {
      state.dashboard.showDocumentsWarningIcon = true;
      state.apeAndDeedOfOrigin.warnings.push(
        bitfToTranslate('COMMON.WARNINGS.APE_SURVEYOR_PICTURE_NOT_LOADED')
      );
    }

    if (this.hasApeSurveyorSurveyNotLoaded()) {
      state.dashboard.showDocumentsWarningIcon = true;
      state.apeAndDeedOfOrigin.warnings.push(
        bitfToTranslate('COMMON.WARNINGS.APE_SURVEYOR_SURVEY_NOT_LOADED')
      );
    }
    return state;
  }

  get isBookletNotComplete() {
    return (
      this.state.dashboard.showInformationWarningIcon ||
      this.state.dashboard.showSurveyorsWarningIcon ||
      this.state.dashboard.showContractsWarningIcon ||
      this.state.dashboard.showDocumentsWarningIcon
    );
  }

  get mapStrapiDescriptionToValue() {
    return {
      data_corrente: new Date().toLocaleDateString('it-IT'),
      note_libretto: this.bookletNotes,
      nome: this.sellerName,
      cognome: this.sellerSurname,
      numero_di_telefono: this.sellerMobilePhone,
      email: this.sellerEmail,
      codice_fiscale: this.sellerFiscalCode,
      indirizzo_venditore: this.sellerAddress,
      data_ultimi_lavori: this.propertyLastWorkData?.toLocaleDateString('it-IT'),
      indirizzo_immobile: this.propertyAddress,
      descrizione_immobile: this.propertyDescription,
      data_inizio_libretto: this.bookletStartDate?.toLocaleDateString('it-IT'),
      data_fine_libretto: this.bookletEndDate?.toLocaleDateString('it-IT'),
      data_accesso_agli_atti:
        this.technicalReportSurveyorAccessToRecordsDate &&
        // eslint-disable-next-line max-len
        `${this.technicalReportSurveyorAccessToRecordsDate.toLocaleDateString(
          'it-IT'
        )} ${this.technicalReportSurveyorAccessToRecordsDate.toLocaleTimeString('it-IT')}`,
      prezzo_accesso_agli_atti: this.accessToRecordsPrice,
      data_inizio_relazione_tecnica: this.technicalReportStartDate?.toLocaleDateString('it-IT'),
      data_di_fine_relazione_tecnica: this.technicalReportEndDate?.toLocaleDateString('it-IT'),
      nota_per_geometra_incaricato_per_relazione_tecnica: this.technicalReportSurveyorNote,
      richiesta_ape: this.requestForApe,
      prezzo_ape: this.apePrice,
      data_di_inizio_ape: this.apeStartDate?.toLocaleDateString('it-IT'),
      data_di_fine_ape: this.apeEndDate?.toLocaleDateString('it-IT'),
      note_per_geometra_incaricato_per_ape: this.apeSurveyorNote,
      prezzo_extra: this.extraPrice,
      data_di_inizio_extra: this.extraStartDate?.toLocaleDateString('it-IT'),
      data_di_fine_extra: this.extraEndDate?.toLocaleDateString('it-IT'),
      note_per_geometra_incaricato_per_extra: this.extraSurveyorNote,
      note_extra_per_proprietario: this.extraNote,
      data_rogito: this.propertyDeedOfSale?.toLocaleDateString('it-IT'),
      // TODO: update invite_seller_email probably will change
      invite_seller_email: this.inviteSellerEmail,
    };
  }

  hasInformationWarnings() {
    const requiredAttributes = [
      'sellerName',
      'sellerSurname',
      'sellerEmail',
      'sellerFiscalCode',
      'sellerMobilePhone',
      'propertyAddress',
      'bookletAttachments.sellerIdentityCard.file',
    ];

    const hasInformationWarnings = requiredAttributes.some((attr) => !get(this.serialised, attr));

    return hasInformationWarnings;
  }

  hasApeNotLoaded() {
    if (this.bookletAttachments?.[EBookletAttachmentName.CASA_DOC_CONTRACT]?.signed) {
      return !this.requestForApe && !this.bookletAttachments.ape?.file;
    }
    return false;
  }

  hasApeSurveyorPicturesNotLoaded() {
    return this.requestForApe && !this.bookletAttachments.apeSurveyorPictures?.file;
  }

  hasApeSurveyorSurveyNotLoaded() {
    return this.requestForApe && !this.bookletAttachments.apeSurveyorSurvey?.file;
  }

  hasDeedOfOriginNotLoaded() {
    if (this.bookletAttachments?.[EBookletAttachmentName.CASA_DOC_CONTRACT]?.signed) {
      return !this.requestForDeedOfOrigin && !this.bookletAttachments.deedOfOrigin?.file;
    }
    return false;
  }

  hasDocumentsNotCompliant() {
    let hasDocumentsNotCompliant = false;
    // Questi documenti generici vendono controllati e dato il warning se non sono compliant
    if (this.bookletAttachments?.landRegistry?.file && !this.bookletAttachments?.landRegistry?.compliant) {
      hasDocumentsNotCompliant = true;
    }
    if (this.bookletAttachments?.urbanPlanning?.file && !this.bookletAttachments?.urbanPlanning?.compliant) {
      hasDocumentsNotCompliant = true;
    }
    if (
      this.bookletAttachments?.occupancyPermit?.file &&
      !this.bookletAttachments?.occupancyPermit?.compliant
    ) {
      hasDocumentsNotCompliant = true;
    }
    if (this.bookletAttachments?.systems?.file && !this.bookletAttachments?.systems?.compliant) {
      hasDocumentsNotCompliant = true;
    }
    if (this.bookletAttachments?.boiler?.file && !this.bookletAttachments?.boiler?.compliant) {
      hasDocumentsNotCompliant = true;
    }
    if (this.bookletAttachments?.cdu?.file && !this.bookletAttachments?.cdu?.compliant) {
      hasDocumentsNotCompliant = true;
    }
    if (
      this.bookletAttachments?.latentDefectsInsurance?.file &&
      !this.bookletAttachments?.latentDefectsInsurance?.compliant
    ) {
      hasDocumentsNotCompliant = true;
    }
    if (this.bookletAttachments?.deedOfOrigin?.file && !this.bookletAttachments.deedOfOrigin.compliant) {
      hasDocumentsNotCompliant = true;
    }
    if (this.bookletAttachments?.ape?.file && !this.bookletAttachments.ape.compliant) {
      hasDocumentsNotCompliant = true;
    }
    if (
      this.bookletAttachments?.apeSurveyorSurvey?.file &&
      !this.bookletAttachments.apeSurveyorSurvey.compliant
    ) {
      hasDocumentsNotCompliant = true;
    }
    if (
      this.bookletAttachments?.apeSurveyorPictures?.file &&
      !this.bookletAttachments.apeSurveyorPictures.compliant
    ) {
      hasDocumentsNotCompliant = true;
    }

    if (
      this.bookletAttachments?.technicalReport?.file &&
      !this.bookletAttachments.technicalReport.compliant
    ) {
      hasDocumentsNotCompliant = true;
    }

    if (this.bookletAttachments?.extra?.file && !this.bookletAttachments.extra.compliant) {
      hasDocumentsNotCompliant = true;
    }

    return hasDocumentsNotCompliant;
  }

  hasDocumentsUnpaid() {
    let hasDocumentsUnpaid = false;

    if (
      this.requestForApe &&
      this.bookletAttachments?.ape?.file &&
      this.bookletAttachments.ape.compliant &&
      !this.bookletAttachments?.ape?.paid
    ) {
      hasDocumentsUnpaid = true;
    }

    if (
      this.bookletAttachments?.technicalReport?.file &&
      this.bookletAttachments.technicalReport.compliant &&
      !this.bookletAttachments?.technicalReport?.paid
    ) {
      hasDocumentsUnpaid = true;
    }

    if (
      this.bookletAttachments?.extra?.file &&
      this.bookletAttachments.extra.compliant &&
      !this.bookletAttachments?.extra?.paid
    ) {
      hasDocumentsUnpaid = true;
    }

    if (
      this.requestForDeedOfOrigin &&
      this.bookletAttachments?.deedOfOrigin?.file &&
      this.bookletAttachments.deedOfOrigin.compliant &&
      !this.bookletAttachments?.deedOfOrigin?.paid
    ) {
      hasDocumentsUnpaid = true;
    }

    return hasDocumentsUnpaid;
  }
}
