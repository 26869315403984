import { Component, computed, input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { IBitfFileUploaderConfig } from '@common/interfaces';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { BitfFile } from '@common/libs/bitforce/core/models';
import { BookletsService, LoaderService, StoreService, UploadService } from '@common/core/services';
import { filter, finalize, switchMap } from 'rxjs/operators';
import { DialogsService } from '@web/core/services';
import { ImagePreviewDialogComponent } from '../image-preview-dialog/image-preview-dialog.component';
import { Booklet, BookletAttachmentComponent, StrapiFile } from '@common/core/models';
import { EBookletAttachmentName } from '@common/enums/attachments.enum';
import { FormControl } from '@angular/forms';
import { CONSTANTS } from '@web/constants';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { EBitfCloseEventStatus } from '@common/enums';
import { eStoreActions } from '@web/enums';

@Component({
    selector: 'casa-doc-document-manager',
    templateUrl: './document-manager.component.html',
    styleUrl: './document-manager.component.scss',
    standalone: false
})
export class DocumentManagerComponent implements OnInit {
  @ViewChild(FileUploaderComponent) fileUploader: FileUploaderComponent;
  @ViewChild('wrapper', { static: true }) wrapper;

  booklet = input<Booklet>();
  attachmentName = input<EBookletAttachmentName>();
  documentName = input<string>();
  uploadPath = input<string>();
  checkCompliance = input<boolean | undefined>();
  checkPayment = input<boolean | undefined>();
  checkSignature = input<boolean | undefined>();
  price = input<string>();
  canUpload = input<boolean>();
  canDelete = input<boolean>();
  isRequired = input<boolean>();
  isVisible = input<boolean>();
  setCompliantTo = input<boolean>(false);
  fileUploaderConfig = input<IBitfFileUploaderConfig>({});

  document = computed(() => this.booklet()?.bookletAttachments?.[this.attachmentName()]);
  showWarningMessage = computed(() => {
    return (
      ((this.checkCompliance() === true && this.document()?.compliant === false) ||
        (this.checkPayment() === true && this.document()?.paid === false) ||
        (this.checkSignature() === true && this.document()?.signed === false)) &&
      this.document()?.file?.length > 0
    );
  });

  selectedTab = new FormControl(0);

  constructor(
    private uploadService: UploadService,
    private dialogsService: DialogsService,
    private bookletService: BookletsService,
    private viewContainerRef: ViewContainerRef,
    private storeService: StoreService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.wrapper);
  }

  onStartUpload(): void {
    const files: BitfFile[] = Array.from(this.fileUploader.uploadableFiles);
    this.loaderService.show();
    this.uploadService
      .uploadMultiple<StrapiFile[]>({
        files,
        fileFormFieldName: 'files',
        body: {
          path: this.uploadPath(),
        },
        disableHideLoader: true,
      })
      .pipe(
        switchMap((files) => {
          this.fileUploader.removeUploadedFiles();
          const updatedFiles = files.map((f) => f.content).reduce((acc, val) => acc.concat(val), []);
          this.booklet().bookletAttachments[this.attachmentName()] = new BookletAttachmentComponent({
            ...this.booklet().bookletAttachments?.[this.attachmentName()],
            compliant: this.setCompliantTo(),
            paid: true,
            file: [
              ...(this.booklet().bookletAttachments?.[this.attachmentName()]?.file?.map((f) => f) || []),
              ...updatedFiles,
            ].filter(Boolean),
          });

          return this.bookletService.updateBookletAttachments(this.booklet());
        }),
        finalize(() => {
          this.selectedTab.setValue(0);
        })
      )
      .subscribe(() => {
        this.reloadBooklet();
      });
  }

  showImagePreview(mediaFile: StrapiFile) {
    if (this.document().paid || !this.checkPayment()) {
      this.dialogsService.dialog
        .open(ImagePreviewDialogComponent, {
          data: {
            imageUrl: mediaFile.url,
          },
        })
        .afterClosed();
    }
  }

  onDownloadDocument(mediaFile: StrapiFile): void {
    if (this.document().paid || !this.checkPayment()) {
      window.open(mediaFile.url, '_blank');
    }
  }

  onDeleteFile(mediaFile: StrapiFile): void {
    this.dialogsService.dialog
      .open(CONSTANTS.okCancelDialogComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          okButtonType: 'ERROR',
          title: bitfToTranslate('BOOKLET_ATTACHMENT.DELETE_FILE'),
          message: bitfToTranslate('BOOKLET_ATTACHMENT.DELETE_FILE_CONFIRMATION'),
          okText: bitfToTranslate('COMMON.LABEL.DELETE'),
          cancelText: bitfToTranslate('COMMON.LABEL.CANCEL'),
        },
      })
      .afterClosed()
      .pipe(
        filter((result) => result?.status === EBitfCloseEventStatus.OK),
        switchMap(() =>
          this.uploadService
            .delete({
              relation: 'files',
              relationId: mediaFile.id,
            })
            .pipe(
              finalize(() => {
                this.reloadBooklet();
              })
            )
        )
      )
      .subscribe();
  }

  private reloadBooklet() {
    this.bookletService.loadFullBooklet(this.booklet()?.documentId).subscribe((response) => {
      this.storeService.setStore((store) => {
        store.booklet = response.content;
      }, eStoreActions.BOOKLET_UPDATED);
    });
  }
}
